body {
  margin: 0;
  padding: 0;
  font-family: "Oxygen";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-background {
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    background-image: url(/static/media/LogoBareFlipped.850da0f7.png);
    background-repeat: no-repeat;
    background-position: center;
}

.login
{
    position: relative;
    width: 220px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    top: 270px;
    background-color: rgba(255,255,255,.9);
    padding: 8px;
}

.login-form
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    top: 200px;
}

.login-email, .login-password, .login-submit
{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.login-submit
{
    margin: 11px;
}

/*.login-email > div*/
/*{*/
    /*padding:15px;*/
/*}*/

.login-error
{
    color: rgb(255,20,5);
    -webkit-align-self: center;
            align-self: center;
    margin: 10px;
}
.container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.top-bar {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;

}

.bottom-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.left-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 350px;
            flex-basis: 350px;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: rgb(244, 248, 253);;


}

.main-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}


.menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
    justify-items: stretch;
    background-color: rgb(0, 188, 212);
    background-image:linear-gradient(to right,rgb(122, 88, 140), rgb(92, 118, 175));

    height: 34px;
}

.menu-bar-left {
    display: -webkit-flex;
    display: flex;
    width:100%;
}

.menu-bar-right {
    display: -webkit-flex;
    display: flex;
    width:100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.menu-bar-item {
    display: -webkit-flex;
    display: flex;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    justify-items: flex-end;
    padding:5px;
    color:white;
}

.sub-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    background-color: rgba(240,240,240, .8);
    z-index: 9000;
    color:black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(15,15,15,.05);
    border-style: solid;
    margin-top:-1px;
}

.hover-sub-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.hover-sub-menu>.menu-label::after {
    content: " \25B6";
    font-size: 8px;
    vertical-align: middle;
}

.menu-label
{
    padding: 6px;
    width:100%;
}

.menu-item {

}

.info-bar {
    padding-left: 10px;
    background-color: rgb(50,50,50);
    color: white;
}
.info-bar button {
    color: white;
}
.info_item {

}

.playback-control, .state-navigator {
    height: 50px;
    background-color: rgb(222, 233, 249);

}

.playback-control
{
    padding-left: 15px;
    width: 280px;

}

.state-navigator {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.state-navigator-top {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width:100%;
}

.navigator-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}

.navigator-right-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:30%;
            flex-basis:30%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.navigator-right-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:fit-content;
            flex-basis:fit-content;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.navigator-center-buttons {
    -webkit-flex-basis:40%;
            flex-basis:40%;
    -webkit-justify-content: center;
            justify-content: center;

}

.navigator-left-buttons {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-basis:30%;
            flex-basis:30%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.navigator-left-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:fit-content;
            flex-basis:fit-content;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.state-navigator-bottom {

    -webkit-justify-content: flex-end;

            justify-content: flex-end;
    height: 100%;
    justify-items: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

}

.peripheral-group
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
}

.peripheral
{
    display: -webkit-flex;
    display: flex;
    padding-left:10px;
}

.peripheral-content
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}

.peripheral-stack
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: fit-content;
            flex-basis: fit-content;
    width: 100%;
}


.peripheral-switch
{
    /*display:inline-block;*/
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    /*transform-origin: center;*/
}

.peripheral-label
{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-self: center;
            align-self: center;
}

.peripheral-switch-container
{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}

.peripheral-electrode-states
{
    padding: 17px;
}
body {
    background-color: rgb(247,247,255);
}

.create-sequence-instructions-background, .create-sequence-state-instructions-background
{
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}

.create-sequence-instructions-background
{
    background-color: rgb(50, 50, 50);
}

.container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.top-bar {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
}

.top-bar {
    background-color: rgb(50,50,50);
}

.create-sequence-instructions, .create-sequence-state-instructions {
    background-color: rgb(190,120,190);
    color: white;
    padding: 10px;
    border-radius: 17px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
}


.bottom-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.left-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 424px;
            flex-basis: 424px;
    -webkit-flex-direction: column;
            flex-direction: column;
    /*background-color: rgb(240, 246, 253);*/
    background-color: transparent;
}

.main-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    /*background-color: rgb(204, 220, 243);*/
    background-color: transparent;
}


.menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    position: relative;
    justify-items: stretch;
    background-color: rgb(0, 188, 212);
    background-image:linear-gradient(to left,rgb(90, 100, 132), rgb(92, 118, 156));
    border-bottom-left-radius: 17px;
    height: 34px;
}

.menu-bar-left {
    display: -webkit-flex;
    display: flex;
    width:100%;
}

.menu-bar-right {
    display: -webkit-flex;
    display: flex;
    width:100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.menu-bar-item {
    display: -webkit-flex;
    display: flex;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    justify-items: flex-end;
    padding:5px;
    color:white;
}

.sub-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    background-color: rgba(240,240,240, .8);
    z-index: 9000;
    color:black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(15,15,15,.05);
    border-style: solid;
    margin-top:-1px;
}

.hover-sub-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.hover-sub-menu>.menu-label::after {
    content: " \25B6";
    font-size: 8px;
    vertical-align: middle;
}

.menu-label
{
    padding: 6px;
    width:100%;
}

.menu-item {

}

.info-bar {
    padding-left: 10px;
    background-color: rgb(50,50,50);
    color: white;
}
.info-bar button {
    color: white;
}
.info_item {

}

.playback-control, .state-navigator {
    height: 50px;
}



.playback-control
{
    background-image:linear-gradient(to bottom right,rgb(50, 50, 50) 50%, rgb(226, 238, 251) 50%);

    width: 100%;
    padding-left: 0px;

}

.playback-control-inside
{
    border-top-left-radius:17px;
    border-bottom-right-radius:17px;

    background-color: rgb(204, 220, 243);
    width: 100%;
    height: 100%;
}
.playback-control-content
{
    padding-left: 17px;

}

.state-navigator-background {
    background-color: rgb(204, 220, 243);
}

.state-navigator {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top-left-radius: 17px;
}

.electrode-state-background
{
    background-color: rgb(226, 238, 251);
}

.state-navigator
{
    background-image: linear-gradient(45deg,rgb(226, 238, 251) 0%, rgb(236, 243, 251) 10%, rgb(236, 243, 251) 100%);
}

.state-navigator-top {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width:100%;
}

.navigator-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}

.navigator-right-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:30%;
            flex-basis:30%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.navigator-right-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:fit-content;
            flex-basis:fit-content;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.navigator-center-buttons {
    -webkit-flex-basis:40%;
            flex-basis:40%;
    -webkit-justify-content: center;
            justify-content: center;
}

.navigator-center-button .record button
{
    color: rgb(169,6,56)
}

.navigator-left-buttons {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-basis:30%;
            flex-basis:30%;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.navigator-left-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis:fit-content;
            flex-basis:fit-content;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.state-navigator-bottom {

    -webkit-justify-content: flex-end;

            justify-content: flex-end;
    height: 100%;
    justify-items: flex-end;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;


}

.navigation-slider
{
    height: 0px;
    border-radius: 7px;
    /*background-color: rgb(100,100,100);*/
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    padding-bottom:5px;
}
.navigation-slider button
{
    background-color: rgb(107, 105, 208);
}

.navigation-slider div
{
    background-color: rgb(100, 111, 146);
}

.navigation-index
{
    color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-basis: 5%;
            flex-basis: 5%;
    -webkit-align-self:center;
            align-self:center;
}

.static-peripheral-group
{
    /*background-color: rgb(204, 220, 243);*/
    background-image:linear-gradient(to right,rgb(204, 220, 243) 50%, rgb(240, 246, 253) 50%);

}

.static-peripheral-group > .peripheral-group
{
    background-color: rgb(226, 238, 251);
    border-top-left-radius: 17px;
    border-bottom-right-radius: 17px;


}

.dynamic-peripheral-group
{
    background-color: rgb(226, 238, 251);
    height:100%;
}

.dynamic-peripheral-group > .peripheral-group
{
    border-top-left-radius: 17px;
    background-color: rgb(240, 246, 253);
    height: 100%;

}

.peripheral-group
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
    border-top-left-radius: 17px;


}

.peripheral
{
    display: -webkit-flex;
    display: flex;
}

.peripheral-content
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    padding-left:10px;

}

.peripheral-stack
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-basis: fit-content;
            flex-basis: fit-content;
    width: 100%;
}


.peripheral-switch
{
    /*display:inline-block;*/
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    /*transform-origin: center;*/
}

.peripheral-label
{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-self: center;
            align-self: center;
}

.peripheral-measurement
{
    font-size: 14px;
    width: 100px;
}


.peripheral-switch-container
{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-basis: 50px;
            flex-basis: 50px;
}

.peripheral-text-field-container
{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-basis: 70px;
            flex-basis: 70px;
}


.peripheral-electrode-states
{
    padding: 17px;
    background-color: white;
    border-top-left-radius: 17px;
    box-shadow: inset 8px 10px 10px 2px rgba(0,0,0,0.03);
}

.browser-container
{
    border-radius:5px;
    margin:20px;
    background-color: rgba(255,255,255,.97);

}
.browser-header-bar {
    background-color: rgb(100,100,255);
    width:100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.browser-header {
padding: 5px;
    color:white;
    text-transform: capitalize;

}

.browser-body
{
    padding:20px;

}
.browser-body button
{
    margin: 5px;
}

.browser-ok-button>button
{
    color: white;
}

.browser-ok-button > button:disabled
{
    background-color: rgba(180,100,100, .3);

}

.browser-ok-button > button:enabled
{
    background-color: rgb(180,100,100);
}


.browser-cancel-button>button
{
    margin: 5px;
}


.browser-option-section
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-basis:100%;
            flex-basis:100%;
}

.browser-collapsed-option-section
{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-basis:100%;
            flex-basis:100%;
}
.browser-collapsed-options
{
    text-align: center;
}
.browser-option
{
    display:-webkit-flex;
    display:flex;
    width: 150px;
    height: 150px;

    border-radius: 15px;
    border-width: 2px;
    border-color: lightgrey;
    border-style: solid;
    margin: 10px;
    padding: 15px;
}

.browser-option-selected
{
    border-color: lightblue;
}

.browser-option>span
{
    display:-webkit-flex;
    display:flex;
    width: 100%;
}

.sequence-thumbnail
{
    width:100%;
}


.calibration-modal
{
    position: absolute;
    background-color: white;
    top: 150px;
    left: 300px;
    border-radius: 17px;
    padding:0px;
    border-style: solid;
    border-width: .5px;
    border-color: lightcyan;
}

.calibration-modal-title-bar
{
    background-color: rgb(100,100,255);
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    padding: 6px;
    color: white;
    font-weight: bold;
    text-transform: capitalize;
}

.calibration-modal-content
{
    padding:10px;
}

.calibration-instructions
{
    padding:10px;
}

.calibration-input-subsection-title
{
    font-weight: bold;
}

.calibration-input-subsection
{
    padding:20px;
}

