.login-background {
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    background-image: url(../../../LogoBareFlipped.png);
    background-repeat: no-repeat;
    background-position: center;
}

.login
{
    position: relative;
    width: 220px;
    height: fit-content;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    top: 270px;
    background-color: rgba(255,255,255,.9);
    padding: 8px;
}

.login-form
{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: center;
    align-content: center;
    top: 200px;
}

.login-email, .login-password, .login-submit
{
    display: flex;
    justify-content: center;
}

.login-submit
{
    margin: 11px;
}

/*.login-email > div*/
/*{*/
    /*padding:15px;*/
/*}*/

.login-error
{
    color: rgb(255,20,5);
    align-self: center;
    margin: 10px;
}