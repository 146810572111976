.container {
    display: flex;
    flex-direction: column;
}

.top-bar {
    flex-direction: column;
    display: flex;

}

.bottom-section {
    display: flex;
    flex-direction: row;
}

.left-bar {
    display: flex;
    flex-basis: 350px;
    flex-direction: column;
    background-color: rgb(244, 248, 253);;


}

.main-section {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}


.menu {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-items: stretch;
    background-color: rgb(0, 188, 212);
    background-image:linear-gradient(to right,rgb(122, 88, 140), rgb(92, 118, 175));

    height: 34px;
}

.menu-bar-left {
    display: flex;
    width:100%;
}

.menu-bar-right {
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: flex-end;
}

.menu-bar-item {
    display: flex;
    width:fit-content;
    justify-items: flex-end;
    padding:5px;
    color:white;
}

.sub-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgba(240,240,240, .8);
    z-index: 9000;
    color:black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(15,15,15,.05);
    border-style: solid;
    margin-top:-1px;
}

.hover-sub-menu {
    display: flex;
    flex-direction: row;
}
.hover-sub-menu>.menu-label::after {
    content: " ▶";
    font-size: 8px;
    vertical-align: middle;
}

.menu-label
{
    padding: 6px;
    width:100%;
}

.menu-item {

}

.info-bar {
    padding-left: 10px;
    background-color: rgb(50,50,50);
    color: white;
}
.info-bar button {
    color: white;
}
.info_item {

}

.playback-control, .state-navigator {
    height: 50px;
    background-color: rgb(222, 233, 249);

}

.playback-control
{
    padding-left: 15px;
    width: 280px;

}

.state-navigator {
    display: flex;
    flex-direction: column;
}

.state-navigator-top {
    display: flex;
    height: 100%;
    width:100%;
}

.navigator-buttons {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
}

.navigator-right-buttons {
    display: flex;
    flex-basis:30%;
    justify-content: flex-end;
}

.navigator-right-button {
    display: flex;
    flex-basis:fit-content;
    justify-content: flex-end;
}

.navigator-center-buttons {
    flex-basis:40%;
    justify-content: center;

}

.navigator-left-buttons {
    display:flex;
    flex-basis:30%;
    justify-content: flex-start;
}

.navigator-left-button {
    display: flex;
    flex-basis:fit-content;
    justify-content: flex-start;
}

.state-navigator-bottom {

    justify-content: flex-end;
    height: 100%;
    justify-items: flex-end;
    display: flex;
    flex-direction: column;

}

.peripheral-group
{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
}

.peripheral
{
    display: flex;
    padding-left:10px;
}

.peripheral-content
{
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
}

.peripheral-stack
{
    display: flex;
    flex-direction: row;
    flex-basis: fit-content;
    width: 100%;
}


.peripheral-switch
{
    /*display:inline-block;*/
    transform: rotate(-90deg);
    /*transform-origin: center;*/
}

.peripheral-label
{
    display:flex;
    justify-content: flex-start;
    flex-basis: 100%;
    align-content: center;
    align-self: center;
}

.peripheral-switch-container
{
    display:flex;
    justify-content: flex-end;
    flex-basis: 100%;
}

.peripheral-electrode-states
{
    padding: 17px;
}